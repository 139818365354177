import request from "./request";

//查询个人认证信息
export const getRealNameStatus = (data) =>
  request({ url: "/platform/identity/getRealNameStatus", method: "post", data });
  
//获取个人认证链接
export const getPsnAuthUrl = (data) =>
  request({ url: "/platform/identity/getPsnAuthUrl", method: "post", data });
  

//查询企业认证信息
export const getOrgIdentityInfo = (data) =>
  request({ url: "/platform/identity/getOrgIdentityInfo", method: "post", data });
  
//获取企业认证链接
export const getOrgAuthUrl = (data) =>
  request({ url: "/platform/identity/getOrgAuthUrl", method: "post", data });
  
  
//取消验证
export const cancelSubmit = () =>
  request({ url: "/platform/identity/cancelSubmit ", method: "get" });
  