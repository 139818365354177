<template>
  <div class="EnterpriseSeal">
    <div class="type1" v-if="type == 3">
      <img
        src="@/assets/images/AccountSettings/seal1.png"
        style="width: 140px"
        alt=""
      />
      <div class="yell">签章获取申请未提交</div>
      <el-button @click="submitForm" type="primary">开始申请签章</el-button>
      <div class="bottoms">
        企业认证及企业签章仅用于平台内企业发布信息的标识，以及平台内交易合同的电子签章盖章用途。
        若企业尚未在电池之家-竞拍平台进行过认证，则企业认证方式共两种，选择其一进行认证即可。可按照以下内容提前准备相关资料。
      </div>
      <div class="tables">
        <div class="item">
          <div class="itemTit">法人认证</div>
          <div class="itemCont">
            <p>法人姓名</p>
            <p>法人身份证号</p>
            <p>法人实名手机号</p>
            <p>企业全称</p>
            <p>企业社会统一信用代码</p>
          </div>
        </div>
        <div class="item">
          <div class="itemTit">对公打款认证</div>
          <div class="itemCont">
            <p>企业全称</p>
            <p>企业社会统一信用代码</p>
            <p>对公账户银行名称</p>
            <p>对公账户银行卡号</p>
            <p>打出对应金额款项到指定账户</p>
          </div>
        </div>
      </div>
    </div>
    <div class="type1" v-if="type == 5 || type == 2">
      <img
        src="@/assets/images/AccountSettings/seal2.png"
        style="width: 140px"
        alt=""
      />
      <div class="yell blue">签章获取申请已提交</div>
      <div class="small">申请资料正在审核中…</div>
      <el-button @click="cancel" type="primary">取消验证</el-button>
      <div class="bottoms">
        企业认证及企业签章仅用于平台内企业发布信息的标识，以及平台内交易合同的电子签章盖章用途。
      </div>
    </div>
    <div class="type1" v-if="type == 0">
      <img
        :src="'/api/file/displayResource?objectName=' + userInfo.sealPath"
        style="width: 140px"
        alt=""
      />
      <div class="yell gree">企业签章获取成功</div>
      <div class="list">
        <div class="listItem">
          <span class="tit">签章有效期至</span>
          <span>{{ form.validDate }}</span>
        </div>
        <div class="listItem">
          <span class="tit">企业名称</span>
          <span>{{ form.name }}</span>
        </div>
        <div class="listItem">
          <span class="tit">统一社会信用代码</span>
          <span>{{ form.manufactoryCode }}</span>
        </div>
      </div>
      <div class="bottoms">
        企业认证及企业签章仅用于平台内企业发布信息的标识，以及平台内交易合同的电子签章盖章用途。
      </div>
    </div>
    <div class="type1" v-if="type == 1">
      <img
        src="@/assets/images/AccountSettings/seal4.png"
        style="width: 140px"
        alt=""
      />
      <div class="yell">企业签章授权到期</div>
      <el-button @click="type = 3" type="primary">重新申请签章</el-button>
      <div class="bottoms">
        企业认证及企业签章仅用于平台内企业发布信息的标识，以及平台内交易合同的电子签章盖章用途。
        若企业尚未在电池之家-竞拍平台进行过认证，则企业认证方式共两种，选择其一进行认证即可。可按照以下内容提前准备相关资料。
      </div>
      <div class="tables">
        <div class="item">
          <div class="itemTit">法人认证</div>
          <div class="itemCont">
            <p>法人姓名</p>
            <p>法人身份证号</p>
            <p>法人实名手机号</p>
            <p>企业全称</p>
            <p>企业社会统一信用代码</p>
          </div>
        </div>
        <div class="item">
          <div class="itemTit">对公打款认证</div>
          <div class="itemCont">
            <p>企业全称</p>
            <p>企业社会统一信用代码</p>
            <p>对公账户银行名称</p>
            <p>对公账户银行卡号</p>
            <p>打出对应金额款项到指定账户</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getOrgIdentityInfo, getOrgAuthUrl, cancelSubmit } from "@/api/esign";
import { queryManuFactoryInfo } from "@/api/personnelManagement";
import { mapState } from "vuex";
export default {
  data() {
    return {
      type: 1,
      form: {
        orgName: "",
        orgIDCardNum: "",
      },
    };
  },
  mounted() {
    // 有效; 0;
    // 已失效; 1;
    // 获取失败; 2;
    // 未申请; 3;
    // 审核中; 5;
    this.$store.dispatch("getUserInfo");
    this.type = this.userInfo.signatureStatus;
    this.getData();
  },
  methods: {
    cancel() {
      cancelSubmit().then(({code}) => {
        if (code == 200) {
          this.$store.dispatch("getUserInfo");
          // this.$forceUpdate();
          this.type = this.userInfo.signatureStatus;
          window.location.reload()
        }
      });
    },
    getData() {
      queryManuFactoryInfo().then(({ code, data }) => {
        if (code == 200) {
          this.form = data;
        }
      });
    },
    submitForm() {
      let { manufactoryCode, name } = this.form;
      let param = {
        orgName: name,
        orgIDCardNum: manufactoryCode,
      };
      getOrgAuthUrl(param).then((res) => {
        if (res.code == 200) {
          this.$store.dispatch("getUserInfo");
          this.type = 5;
          window.open(res.msg);
        }
      });
    },
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
  },
};
</script>

<style lang="less" scoped>
.EnterpriseSeal {
  margin-top: 20px;
  margin-left: 30px;
  // width: 600px;
  .bottoms {
    width: 580px;
    margin: 30px 0 20px;
    color: #999;
    font-size: 14px;
    line-height: 24px;
  }
  .type1 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    .yell {
      color: #ff7f00;
      line-height: 40px;
      height: 50px;
      font-weight: 600;
      font-size: 16px;
    }
    .gree {
      color: #0dac65;
    }
    .blue {
      color: #006cec;
    }
    .small {
      line-height: 80px;
    }
    .list {
      line-height: 24px;
      margin: 20px 0;
      .listItem {
        .tit {
          width: 260px;
          color: #999;
          display: inline-block;
        }
      }
    }
    .tables {
      width: 580px;
      display: flex;
      border: 1px solid #ccc;
      border-left: 2px solid #ccc;
      .item {
        width: 50%;
        border-right: 1px solid #ccc;
        .itemTit {
          height: 46px;
          line-height: 46px;
          text-align: center;
          background-color: #eee;
          font-weight: 600;
          border-bottom: 1px solid #ccc;
        }
        .itemCont {
          padding: 10px 28px;
          line-height: 24px;
        }
      }
    }
  }
}
</style>
